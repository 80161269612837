<template>
  <b-dropdown-form
    class="py-1 dropdown-filter-form-n"
  >
    <b-row>
      <b-col cols="10">
        <p>Filters</p>
      </b-col>
      <b-col cols="2">
        <feather-icon
          icon="XIcon"
          size="16"
          class="align-right"
          @click="$emit('emitHideFilter')"
        />
      </b-col>
    </b-row>
    <b-form-group label="Modules">
      <b-form-checkbox
        v-for="option in moduleOptions"
        :key="option.value"
        v-model="moduleFilterValue"
        :value="option.value"
        name="flavour-3a"
      >
        {{ option.label }}
      </b-form-checkbox>
    </b-form-group>
    <b-button
      variant="outline-primary"
      size="sm"
      class="mr-1"
      @click="clearFilter"
    >
      Clear All
    </b-button>
    <b-button
      variant="primary"
      size="sm"
      @click="applyFilter"
    >
      Apply Filter
    </b-button>
  </b-dropdown-form>
</template>

<script>
import {
  BDropdownForm, BFormGroup, BButton, BRow, BCol, BFormCheckbox,
} from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BDropdownForm,
    BFormGroup,
    BRow,
    BCol,
    BFormCheckbox,
  },
  props: {
    moduleFilter: {
      type: [Array, null],
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    },
    moduleOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      moduleFilterValue: JSON.parse(JSON.stringify(this.moduleFilter)) || [],
    }
  },
  watch: {
    moduleFilter: {
      immediate: true,
      handler(val) {
        this.moduleFilterValue = JSON.parse(JSON.stringify(val)) || []
      },
    },
  },
  methods: {
    applyFilter() {
      this.$emit('update:moduleFilter', this.moduleFilterValue)

      this.$emit('emitHideFilter')
    },
    clearFilter() {
      this.moduleFilterValue = []
      this.$emit('update:moduleFilter', this.moduleFilterValue)

      this.$emit('emitHideFilter')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
